/* Media queries */
@mixin initialQuery {
  @media only screen and (min-width: 0px) {
    @content;
  }
}
@mixin xs {
  @media only screen and (max-width: 400px) {
    @content;
  }
}
@mixin min-sm {
  @media only screen and (min-width: 401px) {
    @content;
  }
}
@mixin sm {
  @media only screen and (max-width: 600px) {
    @content;
  }
}
@mixin md {
  @media only screen and (min-width: 601px) {
    @content;
  }
}
@mixin md-only {
  @media only screen and (max-width: 992px) and (min-width: 601px) {
    @content;
  }
}
@mixin max-768 {
  @media only screen and (max-width: 768px) {
    @content;
  }
}
@mixin min-769 {
  @media only screen and (min-width: 769px) {
    @content;
  }
}
@mixin md-max {
  @media only screen and (max-width: 992px) {
    @content;
  }
}
@mixin lg {
  @media only screen and (min-width: 993px) {
    @content;
  }
}
@mixin lg-only {
  @media only screen and (max-width: 1199px) and (min-width: 993px) {
    @content;
  }
}
@mixin lg-max {
  @media only screen and (max-width: 1199px) {
    @content;
  }
}
@mixin xl {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}
@mixin xl-max {
  @media only screen and (max-width: 1500px) {
    @content;
  }
}
@mixin xxl {
  @media only screen and (min-width: 1501px) {
    @content;
  }
}
@mixin xxxl {
  @media only screen and (min-width: 1950px) {
    @content;
  }
}
@mixin ie{
  @media all and (-ms-high-contrast:none){
    @content; 
  }
}
@mixin edge {
  @supports (-ms-ime-align:auto){
    @content;
  }
}

@mixin background-img($color, $img, $position1, $position2, $size) {
  background-color: $color;
  background-image: $img;
  background-position: $position1, $position2;
  background-repeat: no-repeat;
  background-size: $size;
}
@mixin background-img-clean($color, $position1, $position2, $size) {
  background-color: $color;
  background-position: $position1, $position2;
  background-repeat: no-repeat;
  background-size: $size;
}
/* background-image: url("paper.gif"); */

@mixin transitionR {
  transition: all 0.3s ease !important;
}

@mixin brillico {
  filter: brightness(115%);
}
@mixin oscurico {
  filter: brightness(50%);
}

@mixin sombricaCard {
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}
@mixin sombricaBox {
  box-shadow: 0px 2px 8px rgba(234, 102, 20, 0.5);
}
@mixin sombricaInvalid {
  box-shadow: 0px 2px 8px rgba(234, 20, 20, 0.5);
}
@mixin sombricaValid {
  box-shadow: 0px 2px 8px rgba(56, 234, 20, 0.5);
}

@mixin sombricaInput {
  box-shadow: 0px 2px 5px rgba(234, 102, 20, 0.3);
}

@mixin sombricaTop {
  box-shadow: 0px -2px 5px $negro10;
}

@mixin sombricaTopLight {
  box-shadow: 0px -2px $negro10;
}

@mixin sombricaBottom {
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.05), 0 4px 2px -1px rgba(0,0,0,0.05), 0 2px 6px 0 rgba(0,0,0,0.05);
}

@mixin sombricaSuave {
  box-shadow: 0px 1px 2px $negro10;
}
@mixin sombrica0 {
  box-shadow: 0px 1px 3px $negro15;
}
@mixin sombrica03 {
  box-shadow: 0px 3px 3px $negro15;
}

@mixin sombrica1 {
  box-shadow: 0px 2px 5px $negro15;
}

@mixin sombrica2 {
  box-shadow: 0px 2px 5px $negro30;
}

@mixin sombrica3 {
  box-shadow: 0px 2px 10px $negro50;
}

@mixin sombrica4 {
  box-shadow: 0px 5px 15px $negro50;
}

@mixin centrado-horizontal {
  left: 50%;
  transform: translateX(-50%);
}

@mixin centrado-vertical {
  top: 50%;
  transform: translateY(-50%);
}

@mixin centrado-total {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@mixin centrado-totalI {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
}

@mixin truncated{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin truncated2line{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@mixin italica{
  font-style: italic;
}

///////////////Flexbox////////////////

@mixin flexbox {
  display: flex;
  box-sizing: border-box;
}

.flexbox {
  display: flex;
  box-sizing: border-box;
}

@mixin columnado($columnas, $espaciado) {
  column-count: $columnas;
  column-gap: $espaciado;
}

/* Flex direction */
@mixin flex-column{
  flex-direction: column;
}
@mixin flex-row{
  flex-direction: row;
}

/* Alinear elementos */

@mixin flex($wrap,$justify,$alignI,$alignC){
  display: flex;
  box-sizing: border-box;
  flex-wrap: $wrap;
  justify-content: $justify;
  align-items: $alignI;
  align-content: $alignC;
}

@mixin align-self-start {
  align-self: flex-start;
}

@mixin align-self-center {
  align-self: center;
}

@mixin align-self-end {
  align-self: flex-end;
}

@mixin align-self-baseline {
  align-self: baseline;
}

@mixin align-self-stretch {
  align-self: stretch;
}