body {
  .banner-img {
    background-image: url(../img/banner-home.jpg);
    border-bottom: 3px solid $gris-3;
    padding: 40px 0;
    position: relative;
    @include sm {
      padding: 30px 0;
    }
    .overlay-bk {
      background-color: #fff;
      opacity: 0.7;
    }
    .container {
      position: relative;
      @include lg {
        @include flex(wrap, space-between, flex-end, flex-end);
      }
      @include md-max {
        display: flex;
        flex-direction: column;
        .logo-home {
          order: 1;
        }
        .txt-home {
          order: 3;
        }
        .boton-home {
          order: 2;
        }
      }
      .logo-home {
        text-align: left;
        margin-bottom: 10px;
        @include md-max {
          text-align: center;
        }
        @include xs {
          margin-bottom: 0;
        }
        img {
          max-width: 200px;
          @include xs {
            max-width: 150px;
          }
        }
      }
      .txt-home {
        font-family: $font-h;
        color: $primario-2;
        @include lg {
          padding-right: 15px;
          margin: 0 auto 0 0;
          width: 65%;
        }
        .title,
        .subtitle {
          color: $primario-2;
          @include md-max {
            text-align: center;
          }
        }
        .subtitle {
          margin-top: 5px;
        }
        .title {
          font-weight: 700;
        }
        p {
          line-height: 140%;
          font-size: 1.3rem;
          font-weight: 700;
          @include md-max {
            font-size: 1.2rem;
          }
        }
      }
      .boton-home {
        margin-top: 25px;
        text-align: center;
        @include lg {
          margin-top: 0;
          width: max-content;
        }
        @include md-max {
          margin-top: 10px;
          margin-bottom: 30px;
          width: 100%;
        }
        @include sm {
          margin-bottom: 20px;
        }
        .legend {
          color: $primario-2;
          @include lg {
            font-size: 1.8rem;
          }
          @include max-768 {
            font-size: 1.8rem;
          }
        }
        .btn {
          margin-top: 10px;
          @include lg {
            margin-top: 10px;
            padding-top: 20px;
            padding-bottom: 20px;
          }
          i {
            @include lg {
              transform: translateY(3px);
              font-size: 2rem;
            }
          }
        }
      }
    }
    &.banner-img-comun {
      .container {
        .logo-home {
          @include lg {
            position: absolute;
            top: 0;
            transform: translateY(-5px);
          }
        }
      }
    }
    &#banner-home {
      @include lg {
        @include flex(wrap, center, center, center);
        min-height: 60vh;
        .container {
          @include lg {
            align-items: flex-start;
            align-content: flex-start;
          }
          .logo-home {
            @include lg {
              width: 70%;
            }
          }
        }
      }
    }
  }
  #main-home {
    .row-cards {
      .col {
        @include md-max {
          margin-bottom: 15px;
        }
      }
    }
  }
  .section-home-2 {
    .contenedor-img {
      @include md-max {
        order: 3;
      }
    }
    .contenedor-boton {
      @include md-max {
        order: 2;
      }
    }
  }
  .section-caracteristicas,
  .section-ventajas {
    .container {
      .contenedor-txt-1{
        @include max-768{
          order: 2;
        }
      }
      .contenedor-txt-2 {
        margin-top: 0;
        @include max-768{
          order: 1;
          margin-bottom: 30px;
        }
      }
    }
  }
  .contenedor-caracteristicas {
    margin-top: 20px !important;
    @include flex(wrap, flex-start, stretch, center);
    @include md-max {
      margin: 30px 0 !important;
    }
    @include sm{
      margin-bottom: 0 !important;
    }
    &.contenedor-caracteristicasv{
      margin-top: 0 !important;
    }
    .caracteristica{
      text-align: center;
      padding: 20px 10px;
      img{
        max-width: 60px;
        @include sm{
          max-width: 80px;
        }
      }
      h4{
        margin-top: 5px;
        font-weight: 700;
        font-size: 1.4rem;
        position: relative;
        @include sm{
          font-size: 1.6rem;
        }
        .numero{
          font-size: 2.5rem;
          margin-right: 5px;
          width: 30px;
          display: inline-block;
          @include lg{
            margin-left: 10px;
          }
          @include sm{
            position: absolute;
            left: 15px;
            transform: translateY(-5px);
          }
        }
      }
      p{
        margin-top: 5px;
        color: $primario-2;
        @include md{
          padding: 0 30px;
        }
      }
      a{
        font-size: 1.2rem;
      }
      &.caracteristica-1,
      &.caracteristica-3,
      &.caracteristica-5,
      &.caracteristica-2p{
        @include lg{
          border-right: 2px solid $gris-3;
        }
      }
      &.caracteristica-3p{
        @include lg{
          border-right: none;
        }
      }
      &.caracteristica-1,
      &.caracteristica-2,
      &.caracteristica-3,
      &.caracteristica-4{
        @include lg{
          border-bottom: 2px solid $gris-3;
        }
      }
      &.caracteristica-1,
      &.caracteristica-2,
      &.caracteristica-4,
      &.caracteristica-5{
        @include md-max{
          border-right: 2px solid $gris-3;
        }
      }
      &.caracteristica-1,
      &.caracteristica-2,
      &.caracteristica-3{
        @include md-max{
          border-bottom: 2px solid $gris-3;
        }
      }
      &.caracteristica-p{
        @include lg{
          border-bottom: none;
        }
        @include md-max{
          border: none;
          border-bottom: 2px solid $gris-3;
          &.caracteristica-3p{
            border: none
          }
        }
      }
      &.caracteristica-1,
      &.caracteristica-2,
      &.caracteristica-3,
      &.caracteristica-4,
      &.caracteristica-5{
        @include sm{
          border: none;
          border-bottom: 2px solid $gris-3;
        }
      }
      &.caracteristica-5v,
      &.caracteristica-3p{
        @include sm{
          border: none;
        }
      }
      &.caracteristica-3p{
        @include lg{
          padding-right: 0;
        }
      }
      &.caracteristica-p{
        @include lg{
          img{
            
          }
        }
        @include sm{
          padding-left: 0;
          padding-right: 0;
        }
        .txt{
          padding-top: 15px;
          p{
            padding-left: 100px;
            padding-right: 80px;
            @include md-max{
              padding-right: 0;
              padding-left: 15px;
            }
            @include sm{
              padding: 0;
            }
          }
        }
        .contenedor-video{
          margin-top: 15px;
          @include lg{
            display: none;
          }
        }
        .boton-video{
          padding-top: 10px;
          @include md-max{
            display: none;
          }
          a{
            display: block;
            &:hover{
              @include brillico;
            }
            img{
              max-width: 50px;
            }
          }
        }
      }
    }
  }
}
