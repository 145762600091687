body {

  form{
    margin-top: 40px;
  }

  .label-input {
    pointer-events: none;
    padding-left: 10px;
    font-weight: 400;
    color: $gris-5;
    &.active {
    }
  }
  .input-field > label:not(.label-icon).active {
    color: $secundario-2;
    transform: translateY(-25px) scale(1);
  }

  .small-input {
    display: none;
    line-height: 100%;
    font-size: 0.8rem;
    pointer-events: none;
    color: $secundario-2;
    position: absolute;
    bottom: -20px;
    width: 100%;
    text-align: center;
    @include xs {
      bottom: -15px;
    }
  }

  input:not([type]),
  input[type="text"]:not(.browser-default),
  input[type="password"]:not(.browser-default),
  input[type="email"]:not(.browser-default),
  input[type="url"]:not(.browser-default),
  input[type="time"]:not(.browser-default),
  input[type="date"]:not(.browser-default),
  input[type="datetime"]:not(.browser-default),
  input[type="datetime-local"]:not(.browser-default),
  input[type="tel"]:not(.browser-default),
  input[type="number"]:not(.browser-default),
  input[type="search"]:not(.browser-default),
  textarea.materialize-textarea,
  select.browser-default {
    border-left: none;
    border-right: none;
    border-top: none;
    padding-left: 10px;
    background-color: $blanco90;
    border: 2px solid $secundario-1;
    box-shadow: 0 1px 0 0 $secundario-1;
    color: $primario-2;
    width: calc(100% - 15px);
    font-family: $font-2;
  }
  textarea.materialize-textarea{
    width: 100%;
    min-height: 120px;
  }

  input:not([type]):focus:not([readonly]),
  input[type="text"]:not(.browser-default):focus:not([readonly]),
  input[type="password"]:not(.browser-default):focus:not([readonly]),
  input[type="email"]:not(.browser-default):focus:not([readonly]),
  input[type="url"]:not(.browser-default):focus:not([readonly]),
  input[type="time"]:not(.browser-default):focus:not([readonly]),
  input[type="date"]:not(.browser-default):focus:not([readonly]),
  input[type="datetime"]:not(.browser-default):focus:not([readonly]),
  input[type="datetime-local"]:not(.browser-default):focus:not([readonly]),
  input[type="tel"]:not(.browser-default):focus:not([readonly]),
  input[type="number"]:not(.browser-default):focus:not([readonly]),
  input[type="search"]:not(.browser-default):focus:not([readonly]),
  textarea.materialize-textarea:focus:not([readonly]),
  .select-wrapper input.select-dropdown:focus,
  select.browser-default:focus {
    background-color: #fff;
    border: 2px solid $secundario-2;
    box-shadow: 0 1px 0 0 $secundario-2;
    & + label {
      color: $secundario-2;
    }
  }
  
  input.valid:not([type]),
  input.valid:not([type]):focus,
  input.invalid[type="checkbox"]:not(.browser-default),
  input.invalid[type="checkbox"]:not(.browser-default):focus,
  input.valid[type="text"]:not(.browser-default),
  input.valid[type="text"]:not(.browser-default):focus,
  input.valid[type="password"]:not(.browser-default),
  input.valid[type="password"]:not(.browser-default):focus,
  input.valid[type="email"]:not(.browser-default),
  input.valid[type="email"]:not(.browser-default):focus,
  input.valid[type="url"]:not(.browser-default),
  input.valid[type="url"]:not(.browser-default):focus,
  input.valid[type="time"]:not(.browser-default),
  input.valid[type="time"]:not(.browser-default):focus,
  input.valid[type="date"]:not(.browser-default),
  input.valid[type="date"]:not(.browser-default):focus,
  input.valid[type="datetime"]:not(.browser-default),
  input.valid[type="datetime"]:not(.browser-default):focus,
  input.valid[type="datetime-local"]:not(.browser-default),
  input.valid[type="datetime-local"]:not(.browser-default):focus,
  input.valid[type="tel"]:not(.browser-default),
  input.valid[type="tel"]:not(.browser-default):focus,
  input.valid[type="number"]:not(.browser-default),
  input.valid[type="number"]:not(.browser-default):focus,
  input.valid[type="search"]:not(.browser-default),
  input.valid[type="search"]:not(.browser-default):focus,
  textarea.materialize-textarea.valid,
  textarea.materialize-textarea.valid:focus,
  .select-wrapper.valid > input.select-dropdown,
  .select-wrapper.valid > input.select-dropdown:focus,
  select.browser-default.valid,
  select.browser-default.valid:focus {
    background-color: #fff;
    border: 2px solid $success-1;
    box-shadow: 0 1px 0 0 $success-1;
    & + label {
      color: $success-1;
    }
  }

  input.invalid:not([type]),
  input.invalid:not([type]):focus,
  input.invalid[type="checkbox"]:not(.browser-default),
  input.invalid[type="checkbox"]:not(.browser-default):focus,
  input.invalid[type="text"]:not(.browser-default),
  input.invalid[type="text"]:not(.browser-default):focus,
  input.invalid[type="password"]:not(.browser-default),
  input.invalid[type="password"]:not(.browser-default):focus,
  input.invalid[type="email"]:not(.browser-default),
  input.invalid[type="email"]:not(.browser-default):focus,
  input.invalid[type="url"]:not(.browser-default),
  input.invalid[type="url"]:not(.browser-default):focus,
  input.invalid[type="time"]:not(.browser-default),
  input.invalid[type="time"]:not(.browser-default):focus,
  input.invalid[type="date"]:not(.browser-default),
  input.invalid[type="date"]:not(.browser-default):focus,
  input.invalid[type="datetime"]:not(.browser-default),
  input.invalid[type="datetime"]:not(.browser-default):focus,
  input.invalid[type="datetime-local"]:not(.browser-default),
  input.invalid[type="datetime-local"]:not(.browser-default):focus,
  input.invalid[type="tel"]:not(.browser-default),
  input.invalid[type="tel"]:not(.browser-default):focus,
  input.invalid[type="number"]:not(.browser-default),
  input.invalid[type="number"]:not(.browser-default):focus,
  input.invalid[type="search"]:not(.browser-default),
  input.invalid[type="search"]:not(.browser-default):focus,
  textarea.materialize-textarea.invalid,
  textarea.materialize-textarea.invalid:focus,
  .select-wrapper.invalid > input.select-dropdown,
  .select-wrapper.invalid > input.select-dropdown:focus,
  select.browser-default.invalid,
  select.browser-default.invalid:focus {
    background-color: #fff;
    border: 2px solid $danger-1;
    box-shadow: 0 1px 0 0 $danger-1;
    & + label {
      color: $danger-1;
    }
    & + label + .small-input{
      display: block;
    }
    & + span + .small-input{
      display: block;
    }
  }

  select{
    cursor: pointer;
  }

  .input-field{
    display: block;
    width: 100%;
    margin-top: 0;
    margin-bottom: 40px;
  }
  .input-check{
    @include flex(wrap, flex-start, center, center);
    &>label{
      transform: none;
      width: 100%;
      color: $gris-5;
      position: relative;
      a{
        $color: $secundario-2;
        font-weight: 700;
      }
    }
  }
  .input-boton{
    text-align: center;
    margin-bottom: 0;
  }
  .btn-form{
    width: 100%;
    height: 80px;
    line-height: 80px;
    font-weight: 300;
    font-size: 2rem;
    border: none;
    color: #fff;
    background-color: $secundario-2;
    border-radius: unset;
    &:hover,
    &:focus{
      background-color: $secundario-2;
    }
    i{
      margin-left: 10px;
      font-size: 2.2rem;
    }
  }

  label:not(.label-input) {
    [type="checkbox"].invalid.filled-in:not(:checked){
      & + span:not(.lever):after{
        color: $danger-1;
        border-color: $danger-1;
        background-color: $danger-2;
      }
      & + span{
        color: $danger-1;
      }
    }
    [type="checkbox"]+span:not(.lever){
      padding-left: 30px;
      padding-bottom: 5px;
    }
    [type="checkbox"].filled-in:not(:checked) + span:not(.lever):before {
    }
    [type="checkbox"].filled-in:not(:checked) + span:not(.lever):after {
      border-color: $secundario-2;
      background-color: $blanco10;
    }
    [type="checkbox"].filled-in:checked + span:not(.lever):after {
      border-color: $secundario-2;
      background-color: $secundario-2;
    }
    [type="radio"]:not(:checked) + span:before,
    [type="radio"]:not(:checked) + span:after,
    [type="radio"]:checked + span:before,
    [type="radio"]:checked + span:after,
    [type="radio"].with-gap:checked + span:before,
    [type="radio"].with-gap:checked + span:after {
      border-radius: 0;
    }
    [type="radio"]:not(:checked) + span:before,
    [type="radio"].with-gap:not(:checked) + span:before {
      border-radius: 0;
      border: 2px solid transparent;
    }
    [type="radio"]:not(:checked) + span:before,
    [type="radio"]:not(:checked) + span:after,
    [type="radio"]:checked + span:before,
    [type="radio"]:checked + span:after,
    [type="radio"].with-gap:checked + span:before,
    [type="radio"].with-gap:checked
      + span:after
      [type="radio"]:not(:checked)
      + span:before {
      border-radius: 0;
      border: 2px solid transparent;
    }
    [type="radio"]:checked + span:before,
    [type="radio"].with-gap:checked + span:before {
      border: 2px solid $secundario-2;
      background-color: transparent;
    }
    [type="radio"]:checked + span,
    [type="radio"].with-gap:checked + span {
      color: $primario-1;
      i {
        color: $primario-1;
      }
      svg {
        path {
          fill: $primario-1;
        }
      }
    }
  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $secundario-1;
    opacity: 1; /* Firefox */
    font-family: $font-2;
    font-size: 0.9rem;
  }
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $secundario-1;
    font-family: $font-2;
    font-size: 0.9rem;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $secundario-1;
    font-family: $font-2;
    font-size: 0.9rem;
  }

}
