body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-family: $font-2;
  font-weight: 400;
  color: $primario-1;
  font-size: 1.1rem;
  @include transitionR;
  strong {
    font-weight: 700;
  }
  .condensed {
    font-family: $font-2;
    font-weight: 400;
    &.strong,
    strong {
      font-weight: 700;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    color: $primario-2;
    font-weight: 100;
    font-family: $font-h;
    &.strong,
    strong {
      font-weight: 700;
    }
  }
  h1 {
    font-size: 2.6rem;
    @include max-768 {
      font-size: 2.2rem;
    }
    @include sm {
      font-size: 2rem;
    }
  }
  h2 {
    font-size: 2.2rem;
    @include max-768 {
      font-size: 1.8rem;
    }
  }
  h3 {
    font-size: 2rem;
    @include max-768 {
      font-size: 1.6rem;
    }
  }
  h4 {
    font-size: 1.6rem;
    @include max-768 {
      font-size: 1.3rem;
    }
  }
  h5 {
    font-size: 1.4rem;
    @include max-768 {
      font-size: 1.2rem;
    }
  }
  h6 {
    font-size: 1.2rem;
    @include max-768 {
      font-size: 1.1rem;
    }
  }
  p {
    margin-bottom: 0;
    margin-top: 15px;
  }
  .mt0 {
    margin-top: 0 !important;
  }
  .mb0 {
    margin-bottom: 0 !important;
  }

  .italica {
    font-style: italic;
  }

  .resaltado{
    font-size: 1.2rem;
    font-family: $font-h;
    padding: 15px;
    background-color: $secundario-1-10;
  }

  header#header-principal {
    position: sticky;
    top: 0;
    z-index: 1010;
    @include transitionR;
    &.fixed {
      top: -125px;
      @include sm {
        top: -105px;
      }
      @include xs {
        top: -95px;
      }
    }
    nav.nav-principal {
      position: relative;
      z-index: 20;
      height: 80px;
      background-color: #fff;
      border-bottom: 2px solid $secundario-1;
      @include sombrica1;
      @include sm {
        height: 60px;
      }
      @include xs {
        height: 50px;
      }
      .container {
        width: 100%;
        @include flex(nowrap, space-between, stretch, center);
        .brand-logo {
          position: relative;
          width: auto;
          height: 80px;
          @include flex(wrap, center, center, center);
          @include md-max {
            left: initial;
            transform: none;
          }
          @include sm {
            height: 60px;
          }
          @include xs {
            height: 50px;
          }
          svg {
            @include sm {
              max-width: 180px;
            }
            path {
              color: $secundario-1;
              @include md-max {
                fill: #fff;
              }
            }
          }
          img {
            width: 250px;
            @include sm {
              width: 200px;
              max-height: 30px;
            }
          }
          h1 {
          }
        }
        .menu-navegacion {
          font-family: $font-h;
          @include flex(nowrap, space-between, stretch, center);
          li.menu-item {
            background: none;
            height: 100%;
            position: relative;
            &:hover {
              background: none;
            }
            a.enlace-menu {
              font-weight: 100;
              text-transform: uppercase;
              background: none;
              height: 100%;
              color: $primario-2;
              font-size: 0.9rem;
              line-height: 100px;
              border-bottom: 3px solid transparent;
              &:hover,
              &.active,
              &:focus {
                background: none;
                outline: none;
              }
              &.active,
              &:hover {
                color: $secundario-1;
              }
              &:hover{
                border-bottom: 3px solid $gris-2;
              }
              &.active{
                border-bottom: 3px solid $secundario-1;
                &:hover{
                  border-bottom: 3px solid $secundario-1;
                }
              }
              &:focus {
                color: $secundario-2;
              }
              i {
                font-size: 0.8rem;
                margin-left: 5px;
                color: $primario-2;
              }
            }
            &#menu-info {
              a {
                font-size: 1.2rem;
              }
            }
          }
        }
        .btn-menu-movil {
          display: none;
          float: none;
          margin: 0;
          padding: 0;
          width: 50px;
          height: 78px;
          color: $secundario-1;
          @include md-max {
            @include flex(wrap, center, center, center);
            &.btn-menu {
              justify-content: flex-start;
              padding-left: 10px;
            }
            &.btn-info {
              justify-content: flex-end;
              padding-right: 10px;
            }
          }
          @include sm {
            height: 58px;
          }
          @include xs {
            height: 48px;
          }
          i {
            height: 78px;
            line-height: 78px;
            @include sm {
              height: 58px;
              line-height: 58px;
            }
            @include xs {
              height: 48px;
              line-height: 48px;
            }
          }
        }
      }
    }
  }

  #menu-movil {
    font-family: $font-h;
    box-shadow: none;
    width: 100vw;
    background: none;
    height: 100vh;
    padding-bottom: 0;
    z-index: 1020;
    .cerrar-sidenav {
      text-align: right;
      position: sticky;
      top: 0;
      z-index: 100;
      a {
        color: #fff;
        font-size: 2rem;
        margin: 0;
        padding: 0;
        width: 80px;
        height: 80px;
        line-height: 80px;
        margin-left: auto;
        @include flex(wrap, center, center, center);
        &:hover {
          background: none;
        }
        i {
          color: #fff;
          margin: 0 auto;
        }
      }
    }
    .menu-item {
      width: 100%;
      border-bottom: 1px solid $blanco50;
      @include flex(wrap, flex-start, center, center);
      .enlace-movil {
        width: 100%;
      }
      & > .enlace-movil {
        font-size: 1.3rem;
        height: auto;
      }
      .enlace-movil {
        color: #fff;
        display: block;
        font-weight: 500;
        line-height: 40px;
        padding: 0 32px;
      }
    }
    .menu-item:last-child {
      border: none;
    }
    .menu-item-redes {
      bottom: 0;
      left: 0;
      width: 100vw;
      padding: 20px 0;
      @include flex(wrap, center, center, center);
      a {
        @include flex(wrap, center, center, center);
        width: 33.33%;
        line-height: 110%;
        height: 80px;
        &.facebook {
          /* background-color: $facebook-1; */
        }
        &.twitter {
          /* background-color: $twitter-1; */
        }
        &.youtube {
          /* background-color: $youtube-1; */
        }
        span {
          color: #fff;
          line-height: 110%;
          text-align: center;
          font-size: 0.9rem;
        }
        i {
          color: #fff;
          text-align: center;
          width: 100%;
          margin: 0 auto;
          float: none;
          line-height: 120%;
          height: auto;
          font-size: 1.6rem;
          margin-bottom: 5px;
        }
        i.fa-twitter-x {
          height:26px;
          background-image: url('../../../img/twitter-x.svg');
          background-repeat: no-repeat;
          background-position: bottom;
        }
      }
    }
    .menu-item-fijo {
      position: sticky;
      z-index: 100;
    }
  }

  .sidenav {
    z-index: 1020;
  }
  .sidenav-overlay {
    background-color: $primario-1-98;
    z-index: 1010;
  }

  nav.miguitas {
    height: 25px;
    line-height: 25px;
    .container {
      .ul-miguitas {
        font-size: 0.8rem;
        font-weight: 300;
        font-family: $font-2;
        li {
          a {
            max-width: 250px;
            @include truncated;
            color: $primario-2;
            font-family: $font-2;
            font-size: 0.7rem;
            font-weight: 500;
            opacity: 0.7;
            border-right: 1px solid $negro10;
            line-height: 28px;
            &:hover {
              opacity: 1;
            }
          }
          &.active {
            background-color: $negro5;
            a {
              opacity: 1;
              border: none;
              &:hover {
                background: none;
              }
            }
          }
        }
      }
    }
    &.escritorio {
      background-color: $gris-3;
      @include sombrica0;
      @include md-max {
        display: none;
      }
    }
    &.movil {
      margin-top: 20px;
      min-height: 25px;
      height: auto;
      background-color: $gris-3;
      @include flex(wrap, space-between, center, center);
      @include lg {
        display: none;
      }
      .container {
        .ul-miguitas {
          li {
            margin: 5px 0;
            a {
              font-weight: 400;
              opacity: 0.9;
              font-size: 0.8rem;
              color: $primario-2;
            }
          }
        }
      }
    }
  }

  .row {
    @include flex(wrap, flex-start, stretch, center);
    margin: 0 auto !important;
    width: 100%;
    .col {
      margin-left: unset !important;
      float: none;
      width: 100%;
      @include sm {
        padding: 0;
      }
    }
  }
  .colm {
    margin: 20px 0;
    @include md-max {
      margin: 30px 0;
      text-align: center;
    }
  }

  .container {
    width: 100%;
    max-width: $max-width;
    padding: 0 10px;
    margin: 0 auto;
    &.container-small {
      max-width: $max-width-menos;
    }
  }

  .main-campus {
    @include md {
      min-height: calc(100vh - 365px);
    }
  }

  .section-campus {
    padding: 40px 0;
    background-color: #fff;
    &.section-clarita {
      background-color: $gris-3;
    }
    .container {
      @include lg {
        padding-left: 0;
        padding-right: 0;
      }
      .contenedor-txt,
      .contenedor-boton,
      .contenedor-video {
        margin-top: 20px;
        @include md-max {
          margin-top: 30px;
        }
      }
      .contenedor-img{
        margin-top: 0;
        @include md-max {
          margin-top: 30px;
        }
      }
      .contenedor-txt:first-child {
        margin-top: 0;
      }
      .contenedor-txt {
        p:first-child {
          margin-top: 0;
        }
      }
      .contenedor-img,
      .contenedor-boton {
        @include md-max {
          text-align: center;
        }
      }
      .contenedor-img {
        .col-img{
          margin-bottom: 15px;
        }
        .col-img:last-child{
          @include sm{
            margin-bottom: 0;
          }
        }
        img {
          margin: 0 auto;
          @include sombrica1;
          @include lg {
            width: 100%;
          }
          @include sm{
            width: 100%;
          }
        }
      }
      .contenedor-video {
        .video-container {
          padding-bottom: 54.5%;
          @include sombrica2;
        }
      }
    }
  }

  .section-bk {
    position: relative;
    @include background-img-clean(#fff, center, center, cover);
    .overlay-bk {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0.5;
    }
    .container {
      position: relative;
      z-index: 2;
    }
  }

  #footer {
    position: relative;
    @include sombricaTop;
    border-top: 2px solid $secundario-2;
    font-family: $font-h;
    text-align: center;
    z-index: 10;
    color: $primario-2;
    @include sombrica1;
    @include lg {
      height: 250px;
      @include flex(nowrap, center, center, center);
    }
    .container {
      @include md-max {
        padding: 40px 10px 20px 10px;
      }
      a {
        color: $primario-2;
      }
      p {
        line-height: 130%;
        margin: 0;
        font-weight: 700;
      }
      .logo {
        margin-bottom: 10px;
        img {
          max-width: 200px;
        }
      }
      .txt {
        font-size: 1.1rem;
        margin-bottom: 15px;
      }
      .tel {
        font-size: 1.6rem;
        font-weight: 100;
        margin-bottom: 5px;
        i {
          opacity: 0.5;
          font-size: 1.2rem;
          margin-right: 10px;
          position: relative;
          vertical-align: middle;
          transform: translateY(-3px);
        }
      }
      .fax {
        margin-bottom: 15px;
      }
      .direccion {
        margin-bottom: 15px;
        font-size: 0.8rem;
      }
      .centros {
        margin-bottom: 25px;
        font-size: 0.9rem;
      }
      .tel,
      .centros {
        @include min-769 {
          a:hover {
            color: $secundario-1;
          }
        }
      }
      .redes-sociales {
        margin: 0 auto;
        @include flex(nowrap, center, center, center);
        li {
          margin: 0 10px;
          a {
            width: 30px;
            display: inline-block;
            text-align: center;
            font-size: 1.4rem;
            opacity: 0.7;
            &:hover {
              @include min-769 {
                opacity: 1;
                color: $secundario-1;
              }
            }
            i.fa-twitter-x {
              height:20px;
              width: 20px;
              background-image: url('../../../img/twitter-x.svg');
              background-repeat: no-repeat;
              background-position: bottom;
              transition: background-color 0.3s;
              filter: invert(65%);
              vertical-align: middle; 
            }
            i.fa-twitter-x:hover {
                background-image: url('../../../img/twitter-x-naranja.svg');
                filter: unset;
            }
          }
        }
      }
    }
  }
  .info-footer {
    min-height: 35px;
    text-align: center;
    background-color: $gris-1;
    color: $negro65;
    font-size: 0.7rem;
    padding: 8px 0;
    .container {
      padding-top: 2px;
    }
    a {
      color: $primario-2;
      &:hover {
        color: $primario-2;
        text-decoration: underline;
      }
      @include sm {
        margin: 5px;
        display: inline-block;
      }
    }
    .enlace-pie{
      font-size: 0.9rem;
    }
  }
}
