body {
  .rojo {
    color: $secundario-1;
  }
  .bk-rojo {
    color: $secundario-1;
  }
  a,
  .btn,
  .enlace {
    @include transitionR;
  }
  a,
  .enlace {
    font-weight: 700;
    color: $secundario-1;
    &:hover {
      color: $secundario-2;
    }
  }
  .btn {
    color: #fff;
    background-color: $secundario-1;
    border: 2px solid $secundario-1;
    font-weight: 400;
    font-family: $font-2;
    font-size: 1.1rem;
    border-radius: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    height: auto;
    line-height: 130%;
    @include sombrica1;
    @include xs{
      width: 100%;
    }
    &:hover,
    &:focus {
      color: #fff;
      background-color: $secundario-2;
      border-color: $secundario-2;
      @include sombrica2;
    }
    &.btn-big {
      font-size: 1.3rem;
      padding-top: 15px;
      padding-bottom: 15px;
      i{
        font-size: 1.6rem;
      }
    }
    &.btn-outline {
      border: 2px solid $secundario-1;
      background-color: #fff;
      color: $secundario-1;
      &:hover,
      &:focus {
        color: $secundario-1-80;
        border-color: $secundario-1-80;
        background-color: #fff;
      }
    }
    i {
      margin-left: 10px;
    }
  }
  .btn-floating {
    color: #fff;
    background-color: $secundario-1;
    &:hover,
    &:focus {
      color: #fff;
      background-color: $secundario-2;
    }
  }

  .card {
    height: 100%;
    margin: 0;
    @include sombrica0;
    .card-title,
    p,
    img {
      @include transitionR;
    }
    .card-image {
      img {
      }
    }
    .card-content {
      .card-title {
        margin-top: 15px;
        line-height: 110%;
      }
      p {
        color: $primario-2;
        line-height: 140%;
        font-size: 1.2rem;
        font-weight: 400;
      }
    }
  }
  a.card {
    display: block;
    &:hover {
      @include brillico;
      @include sombrica1;
      .card-title,
      p {
        color: $secundario-1;
      }
      .btn-floating {
        background-color: $secundario-2;
      }
    }
  }
  .modal-overlay{
    z-index: 1015 !important;
  }
  .modal{
    z-index: 1020 !important;
    max-height: initial;
    .modal-title{
      margin-bottom: 15px;
    }
    .modal-footer{
      height: auto;
    }
  }
}
