body{
  .cc-grower{
    font-family: $font-2;
    @include transitionR;
    .cc-window{
      @include flex(wrap, center, center, center);
      padding: 10px;
      .cc-message{
        @include max-768{
          width: 100%;
        }
      }
      .cc-compliance{
        @include max-768{
          width: 100%;
          margin-top: 10px;
        }
        @include min-769{
          margin-left: 15px;
        }
        a{
          outline: none;
          width: 100%;
          cursor: pointer;
          font-weight: 500;
          padding: 5px 15px;
          text-transform: uppercase;
          border: 2px solid $secundario-1;
          @include max-768{
            font-size: 1.2rem;
            width: 100%;
            display: block;
            text-align: center;
          }
        }
      }
    }
  }
}