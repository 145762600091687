body {
  @include lg {
    .col-lg-10 {
      width: 10%;
    }
    .col-lg-20 {
      width: 20%;
    }
    .col-lg-25 {
      width: 25%;
    }
    .col-lg-30 {
      width: 30%;
    }
    .col-lg-33 {
      width: 33.33%;
    }
    .col-lg-40 {
      width: 40%;
    }
    .col-lg-50 {
      width: 50%;
    }
    .col-lg-60 {
      width: 60%;
    }
    .col-lg-70 {
      width: 70%;
    }
    .col-lg-75 {
      width: 75%;
    }
    .col-lg-80 {
      width: 80%;
    }
    .col-lg-90 {
      width: 90%;
    }
    .col-lg-100 {
      width: 100%;
    }
  }
  @include md-max {
    .col-md-10 {
      width: 10%;
    }
    .col-md-20 {
      width: 20%;
    }
    .col-md-25 {
      width: 25%;
    }
    .col-md-30 {
      width: 30%;
    }
    .col-md-33 {
      width: 33.33%;
    }
    .col-md-40 {
      width: 40%;
    }
    .col-md-50 {
      width: 50%;
    }
    .col-md-60 {
      width: 60%;
    }
    .col-md-70 {
      width: 70%;
    }
    .col-md-75 {
      width: 75%;
    }
    .col-md-80 {
      width: 80%;
    }
    .col-md-90 {
      width: 90%;
    }
    .col-md-100 {
      width: 100%;
    }
  }
  @include max-768 {
    .col-768-10 {
      width: 10%;
    }
    .col-768-20 {
      width: 20%;
    }
    .col-768-25 {
      width: 25%;
    }
    .col-768-30 {
      width: 30%;
    }
    .col-768-33 {
      width: 33.33%;
    }
    .col-768-40 {
      width: 40%;
    }
    .col-768-50 {
      width: 50%;
    }
    .col-768-60 {
      width: 60%;
    }
    .col-768-70 {
      width: 70%;
    }
    .col-768-75 {
      width: 75%;
    }
    .col-768-80 {
      width: 80%;
    }
    .col-768-90 {
      width: 90%;
    }
    .col-768-100 {
      width: 100%;
    }
  }
  @include sm {
    .col-sm-10 {
      width: 10%;
    }
    .col-sm-20 {
      width: 20%;
    }
    .col-sm-25 {
      width: 25%;
    }
    .col-sm-30 {
      width: 30%;
    }
    .col-sm-33 {
      width: 33.33%;
    }
    .col-sm-40 {
      width: 40%;
    }
    .col-sm-50 {
      width: 50%;
    }
    .col-sm-60 {
      width: 60%;
    }
    .col-sm-70 {
      width: 70%;
    }
    .col-sm-75 {
      width: 75%;
    }
    .col-sm-80 {
      width: 80%;
    }
    .col-sm-90 {
      width: 90%;
    }
    .col-sm-100 {
      width: 100%;
    }
  }
  .col-10 {
    width: 10%;
  }
  .col-20 {
    width: 20%;
  }
  .col-25 {
    width: 25%;
  }
  .col-30 {
    width: 30%;
  }
  .col-33 {
    width: 33.33%;
  }
  .col-40 {
    width: 40%;
  }
  .col-50 {
    width: 50%;
  }
  .col-60 {
    width: 60%;
  }
  .col-70 {
    width: 70%;
  }
  .col-75 {
    width: 75%;
  }
  .col-80 {
    width: 80%;
  }
  .col-90 {
    width: 90%;
  }
  .col-100 {
    width: 100%;
  }

  /* Márgenes */
  .mt5{
    margin-top: 5px !important;
  }
  .mt10{
    margin-top: 10px !important;
  }
  .mt15{
    margin-top: 15px !important;
  }
  .mt20{
    margin-top: 20px !important;
  }
  .mt25{
    margin-top: 25px !important;
  }
  .mt30{
    margin-top: 30px !important;
  }
  .mt35{
    margin-top: 35px !important;
  }
  .mt40{
    margin-top: 40px !important;
  }
  .mt45{
    margin-top: 45px !important;
  }
  .mt50{
    margin-top: 50px !important;
  }
  .mt55{
    margin-top: 55px !important;
  }
  .mt60{
    margin-top: 60px !important;
  }
  .mt65{
    margin-top: 65px !important;
  }
  .mt70{
    margin-top: 70px !important;
  }
  .mt75{
    margin-top: 75px !important;
  }
  .mt80{
    margin-top: 80px !important;
  }
  .mt85{
    margin-top: 85px !important;
  }
  .mt90{
    margin-top: 90px !important;
  }
  .mt100{
    margin-top: 100px !important;
  }
  .mt120{
    margin-top: 120px !important;
  }
  .mt150{
    margin-top: 150px !important;
  }
  .mb5{
    margin-bottom: 5px !important;
  }
  .mb10{
    margin-bottom: 10px !important;
  }
  .mb15{
    margin-bottom: 15px !important;
  }
  .mb20{
    margin-bottom: 20px !important;
  }
  .mb25{
    margin-bottom: 25px !important;
  }
  .mb30{
    margin-bottom: 30px !important;
  }
  .mb35{
    margin-bottom: 35px !important;
  }
  .mb40{
    margin-bottom: 40px !important;
  }
  .mb45{
    margin-bottom: 45px !important;
  }
  .mb50{
    margin-bottom: 50px !important;
  }
  .mb55{
    margin-bottom: 55px !important;
  }
  .mb60{
    margin-bottom: 60px !important;
  }
  .mb65{
    margin-bottom: 65px !important;
  }
  .mb70{
    margin-bottom: 70px !important;
  }
  .mb75{
    margin-bottom: 75px !important;
  }
  .mb80{
    margin-bottom: 80px !important;
  }
  .mb85{
    margin-bottom: 85px !important;
  }
  .mb90{
    margin-bottom: 90px !important;
  }
  .mb100{
    margin-bottom: 100px !important;
  }
  .mb120{
    margin-bottom: 120px !important;
  }
  .mb150{
    margin-bottom: 150px !important;
  }
  .pt0{
    padding-top: 0 !important;
  }
  .pt5{
    padding-top: 5px !important;
  }
  .pt10{
    padding-top: 10px !important;
  }
  .pt15{
    padding-top: 15px !important;
  }
  .pt20{
    padding-top: 20px !important;
  }
  .pt25{
    padding-top: 25px !important;
  }
  .pt30{
    padding-top: 30px !important;
  }
  .pt35{
    padding-top: 35px !important;
  }
  .pt40{
    padding-top: 40px !important;
  }
  .pt45{
    padding-top: 45px !important;
  }
  .pt50{
    padding-top: 50px !important;
  }
  .pt55{
    padding-top: 55px !important;
  }
  .pt60{
    padding-top: 60px !important;
  }
  .pt65{
    padding-top: 65px !important;
  }
  .pt70{
    padding-top: 70px !important;
  }
  .pt75{
    padding-top: 75px !important;
  }
  .pt80{
    padding-top: 80px !important;
  }
  .pt85{
    padding-top: 85px !important;
  }
  .pt90{
    padding-top: 90px !important;
  }
  .pt100{
    padding-top: 100px !important;
  }
  .pt120{
    padding-top: 120px !important;
  }
  .pt150{
    padding-top: 150px !important;
  }
  .pb0{
    padding-bottom: 0 !important;
  }
  .pb5{
    padding-bottom: 5px !important;
  }
  .pb10{
    padding-bottom: 10px !important;
  }
  .pb15{
    padding-bottom: 15px !important;
  }
  .pb20{
    padding-bottom: 20px !important;
  }
  .pb25{
    padding-bottom: 25px !important;
  }
  .pb30{
    padding-bottom: 30px !important;
  }
  .pb35{
    padding-bottom: 35px !important;
  }
  .pb40{
    padding-bottom: 40px !important;
  }
  .pb45{
    padding-bottom: 45px !important;
  }
  .pb50{
    padding-bottom: 50px !important;
  }
  .pb55{
    padding-bottom: 55px !important;
  }
  .pb60{
    padding-bottom: 60px !important;
  }
  .pb65{
    padding-bottom: 65px !important;
  }
  .pb70{
    padding-bottom: 70px !important;
  }
  .pb75{
    padding-bottom: 75px !important;
  }
  .pb80{
    padding-bottom: 80px !important;
  }
  .pb85{
    padding-bottom: 85px !important;
  }
  .pb90{
    padding-bottom: 90px !important;
  }
  .pb100{
    padding-bottom: 100px !important;
  }
  .pb120{
    padding-bottom: 120px !important;
  }
  .pb150{
    padding-bottom: 150px !important;
  }
}
