/*Colores*/
$primario-1: #333333;
$primario-1-98: rgba(51, 51, 51, 0.98);
$primario-1-90: rgba(27, 27, 27, 0.9);
$primario-2: #545454;
$primario-2-90: rgba(84, 84, 84, 0.90);
$primario-2-98: rgba(84, 84, 84, 0.98);

$secundario-1: #F56600;
$secundario-2: #FC9900;
$secundario-1-98: rgba(245, 102, 0, 0.98);
$secundario-1-95: rgba(245, 102, 0, 0.95);
$secundario-1-90: rgba(245, 102, 0, 0.9);
$secundario-1-80: rgba(245, 102, 0, 0.8);
$secundario-1-50: rgba(245, 102, 0, 0.5);
$secundario-1-15: rgba(245, 102, 0, 0.15);
$secundario-1-10: rgba(245, 102, 0, 0.1);
$secundario-1-5: rgba(245, 102, 0, 0.05);

$gris-1: #C2C2C2;
$gris-2: #CECECE;
$gris-3: #F0F0F0;
$gris-3b: #f7f7f7;

$gris-4: #DCE1E5;
$gris-5: #EDF1F3;

$light-1: #ffe7d5;
$success-1: #16997a;
$success-2: #c8f0e7;
$warning-1: #be8822;
$warning-2: #f7e5c5;
$danger-1: #811010;
$danger-2: #ecb8b8;

$facebook-1: #3B5998;
$facebook-2: #22438b;
$twitter-1: #000000;
$twitter-2: #353535;
$youtube-1: #C81C1E;
$youtube-2: #a51e20;
$maps: #4285f4;

$facebook: #3b5998;
$twitter: #00acee;
$linkedin: #0e76a8;
$wathsapp: #27d367;

$blanco90: rgba(255, 255, 255, 0.9);
$blanco80: rgba(255, 255, 255, 0.8);
$blanco65: rgba(255, 255, 255, 0.65);
$blanco50: rgba(255, 255, 255, 0.5);
$blanco30: rgba(255, 255, 255, 0.3);
$blanco15: rgba(255, 255, 255, 0.15);
$blanco10: rgba(255, 255, 255, 0.1);
$blanco5: rgba(255, 255, 255, 0.05);

$negro98: rgba(0, 0, 0, 0.98);
$negro90: rgba(0, 0, 0, 0.9);
$negro80: rgba(0, 0, 0, 0.8);
$negro65: rgba(0, 0, 0, 0.65);
$negro50: rgba(0, 0, 0, 0.5);
$negro30: rgba(0, 0, 0, 0.3);
$negro15: rgba(0, 0, 0, 0.15);
$negro10: rgba(0, 0, 0, 0.1);
$negro5: rgba(0, 0, 0, 0.05);

/*Degradados*/
@mixin degradado-claro{
  background: #fff;
  background: linear-gradient(0deg, #EDF1F3 0%, #ffffff 100%);
  @include max-768{
    background: linear-gradient(0deg, #d3d8db 0%, #ffffff 100%);
  }
}

/*Tipografía*/
@font-face {
  font-family:"Mic32_Bold";
  src: 
    url("../fonts/Mic32_Bold.ttf?") format("truetype");
  font-weight:normal;
  font-style:normal;
}
@font-face {
  font-family:"Mic32";
  src:
    url("../fonts/Mic32.eot") format("eot"),
    url("../fonts/Mic32.eot") format("eot"),
    url("../fonts/Mic32.woff") format("woff"),
    url("../fonts/Mic32.ttf") format("truetype");
  font-weight:normal;
  font-style:normal;
}

$font-1: 'Roboto', sans-serif;
$font-2: 'Roboto Condensed', sans-serif;
$font-h: 'Mic32', sans-serif;
$font-h-b: 'Mic32_Bold', sans-serif;
$font-i: "Font Awesome 5 Free";

/*Medidas*/
$max-width: 1500px;
$max-width-menos: 1200px;